




























import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'ConfirmationDialog',
})
export default class ConfirmationDialog extends Vue {
  @Prop({ default: false, required: false, type: Boolean }) state!: boolean;
  @Prop({ default: null, type: Number }) slug!: string;
}
